import React from "react"
import "./links.css";

import Link_1 from '../../images/about/link_1.png';
import Link_2 from '../../images/about/link_2.png';
import Link_3 from '../../images/about/link_3.png';
import Link_5 from '../../images/about/link_5.png';
import Links_sign from '../../images/about/links_sign2.png';

const Links = () => (
  <section className="links">
    <div className="sign">
      <img alt="Sign" src={Links_sign} className="scale-to-fit" />
    </div>
    <ul>
      <li>
        <a href='https://www.facebook.com/hoshinamilly/' target="_blank" rel="noreferrer" className="op-effect"><img alt="facebook" src={Link_1} className="scale-to-fit" /></a>
      </li>
      <li>
        <a href='https://ameblo.jp/hello-milly/' target="_blank" rel="noreferrer" className="op-effect"><img alt="AmebaBlog" src={Link_2} className="scale-to-fit" /></a>
        <div className="popup">
          <p>
            アメーバブログ公式ブロガー<br />
            \ アメトピにも掲載されました！/
          </p>
        </div>
      </li>
      <li>
        <a href='https://www.instagram.com/hoshina__milly/' target="_blank" rel="noreferrer" className="op-effect"><img alt="Instagram" src={Link_3} className="scale-to-fit" /></a>
      </li>
      <li>
        <img alt="Online Store" src={Link_5} className="scale-to-fit" />
        <div className="popup" style={{ top: '-32px' }}>
          <span>\ Comming soon /</span>
        </div>
      </li>
    </ul>
  </section>
);

export default Links;
