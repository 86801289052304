import React from "react"
import { Link } from "gatsby"
import "./history.css";

const History = () => (
  <section className="history">
    <div className="chronology">
      <div className="head">-HISTORY-</div>
      <ul>
        <li>
          <div className="year">2009年</div>
          <div className="state">おなまえメソッド®を考案。個人セッションを開始</div>
        </li>
        <li>
          <div className="year">2010年</div>
          <div className="state">おなまえメソッド®を更に広げるべく 、カウンセラーの養成講座を始める。</div>
        </li>
        <li>
          <div className="year">2016年</div>
          <div className="state">おなまえ®カードの制作・監修を手がけ発売。</div>
        </li>
        <li>
          <div className="year">同年7/20</div>
          <div className="state">「おなまえ®」の商標登録（第5864145号）認定。</div>
        </li>
        <li>
          <div className="year">同年11/11</div>
          <div className="state">「おなまえメソッド®」商標登録（第5895024号）認定。</div>
        </li>
        <li>
          <div className="year">2020年</div>
          <div className="state">アメブロオフィシャルブロガー認定</div>
        </li>
        <li>
          <div className="year">同年 7月</div>
          <div className="state">フォレスト出版にて動画コンテンツの販売開始</div>
        </li>
      </ul>
    </div>
    <div className="actual">
      <div className="head">-実績-</div>
      <ul>
        <li>個人カウンセリング</li>
        <li>私分析学講座</li>
        <li>名前を通しのコニュニケーション方などのイベントやコラボセミナー</li>
        <li>法人向けコミュニケーション講座</li>
        <li>教職員向けコミュニケーション講座</li>
        <li>大手芸能プロダクション会社名命名</li>
        <li>タレント命名</li>
        <li>占い・カウンセリングコンテンツ構成</li>
        <li>サンクチュアリ出版トークイベント</li>
        <li>サンクチュアリ出版youtube出演</li>
      </ul>
    </div>
    <div className="btn-holder">
      <Link to='/specialmenu'>星名ミリーの特別メニューはこちら</Link>
    </div>
  </section>
);

export default History;
