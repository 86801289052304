import React from "react"
import "./top.css";

import Image_back from '../../images/about/image_back.jpg';
import Image_text from '../../images/about/image_text.png';

const AboutTop = () => (
  <section className="top">
    <div className="upper">
      <div className="photo">
        <img alt="Name" src={Image_back} className="scale-to-fit" />
      </div>
      <div className="name">
        <img alt="MIlLY HOSHINA" src={Image_text} className="scale-to-fit" />
      </div>
    </div>
    <div className="copy vertical ff-min">
      <span>「名前を紐解けば、</span>
      <span>自分が全て紐解ける」</span>
    </div>
  </section>
);

export default AboutTop;
