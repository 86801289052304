import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

import Top from "../components/about/top";
import Profile from "../components/about/profile";
import History from "../components/about/history";
import Links from "../components/about/links";

const AboutPage = () => (
  <Layout>
    <SEO title="ABOUT" />
    <div className="about">
      <Top />
      <Profile />
      <History />
      <Links />
    </div>
  </Layout>
)

export default AboutPage
