import React from "react"
import "./profile.css";

import Profile from '../../images/about/profile.jpg';

const AboutProfile = () => (
  <section className="profile">
    <div className="read">
      <ul>
        <li>小さな頃から、自分の名前に愛着があり、呼ばれ方や表記にもこだわりのある子どもでした。</li>
        <li>3人の子供の名付けの時に、「名前が人生を左右するほどの鍵となる」と気付き</li>
        <li>画数による姓名判断、言霊などを独学で学びましたが、同じ名前や画数だからといって</li>
        <li>全く同じ人生を歩むのか？という疑問が生まれ、そこから更に学びを深めるために</li>
        <li>心理学、脳科学、社会学、アナグラムなどを多角的に学び、おなまえと向き合い生まれたのが</li>
        <li>おなまえメソッド&reg;です。</li>
      </ul>
    </div>
    <div className="information">
      <div className="inner">
        <p className="name">MILLY HOSHINA</p>
        <p className="brand">
          ONAMAE METHOD&reg;<br />
          私分析学おなまえメソッド&reg;
        </p>
        <p className="ja">代表 星名 美利</p>
      </div>
    </div>
    <div className="photo">
      <img alt="Profile" src={Profile} className="scale-to-fit" />
    </div>
  </section>
);

export default AboutProfile;
